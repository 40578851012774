<template>
  <div v-if="notice" class="w-full flex flex-col gap-3">
    <div class="flex flex-col">
      <div class="font-bold">Font Awesome Icon</div>
      <div class="text-xs">To find an icon, search the font awesome icon library <a href="https://fontawesome.com/icons" class="text-theme-400 underline hover:text-theme-200" target="_blank">here.</a> After searching, apply the "Free" filter, as we do not have a Font Awesome license. Only enter the icon portion, ie: "fa-bell"</div>
      <input class="form-control" placeholder="font awesome icon" v-model="notice.faIcon" />
      <div class="flex gap-2 items-center justify-end">
        <div>Icon Preview:</div>
        <i :class="['fas', notice.faIcon]" />
      </div>
    </div>
    <div class="flex flex-col">
      <div class="font-bold">Title</div>
      <input class="form-control" placeholder="title" v-model="notice.title" />
    </div>
    <div class="flex flex-col">
      <div class="font-bold">Message</div>
      <textarea class="form-control" placeholder="message" v-model="notice.message" rows="4"></textarea>
    </div>
    <div class="flex flex-col">
      <div class="font-bold">Roles</div>
      <div class="text-xs">Optionally only show this notice to users of the selected roles. If no roles are set, this notice will be shown to all users.</div>
      <v-select :options="roles" v-model="notice.roles" :multiple="true" placeholder="select the roles for this notice" :append-to-body="true"></v-select>
    </div>

    <button class="btn btn-theme" @click="isPreviewing = true"><i class="fas fa-magnifying-glass" /> Preview Notice</button>

    <information-notice v-if="isPreviewing" :notices="[notice]" @close-notice="isPreviewing = false"></information-notice>
  </div>
</template>

<script>
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'
import InformationNotice from '@/components/InformationNotice'

export default {
  name: 'notice-editor',
  mixins: [
    FullScreenLoadingMixin
  ],
  emits: ['update:modelValue'],
  components: {
    InformationNotice
  },
  props: {
    modelValue: { type: Object, required: true }
  },
  data () {
    return {
      notice: null,
      roles: [],
      isPreviewing: false
    }
  },
  watch: {
    modelValue () {
      this.resetModel()
    },
    notice: {
      handler () {
        this.$emit('update:modelValue', this.notice)
      },
      deep: true
    }
  },
  methods: {
    resetModel () {
      this.notice = this.modelValue
    }
  },
  mounted () {
    this.resetModel()

    const self = this
    this.$store.dispatch('fetchAdminEntities', 'roles').then(response => {
      self.roles = response.data.map(role => {
        return role.name
      })
    }).catch(error => {
      console.error('error loading user roles', error)
    })
  }
}
</script>
