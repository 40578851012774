<script>
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'search-data-mixin',
  methods: {
    generateTitle (search) {
      return search.datasetDescription.datasetId
    },
    generateSubtitle (search) {
      if (search.isSample) return 'Sample'
      if (!search.datasetDescription.features.includes('COUNT')) {
        if (search.results === null) {
          if (search.searchError) {
            return 'error performing search'
          }
          return 'loading result count...'
        }
        return `${search.results.length.toLocaleString()} Result${search.results.length === 1 ? '' : 's'}`
      }
      if (search.totalResultCount === null || search.totalResultCount < 0) {
        if (search.searchError) {
          return 'error performing search'
        } else if (search.countError) {
          return 'unknown total result count'
        }
        return 'loading result count...'
      }
      return `${search.totalResultCount.toLocaleString()} Result${search.totalResultCount === 1 ? '' : 's'}`
    },
    generateFiltersUsed (search) {
      if (search.filters === null) return []

      let filters = []

      if (search.filters.timeRange !== null) {
        filters.push({
          id: uuidv4(),
          name: 'Time Range',
          icon: 'fa-clock',
          value: search.filters.timeRange.textual,
          type: 'timeRange',
          operator: null,
          isEditable: true
        })
      }

      if (search.filters.geospatial !== null) {
        filters.push({
          id: uuidv4(),
          name: 'Geospatial',
          icon: 'fa-globe',
          value: search.filters.geospatial.textual,
          type: 'geospatial',
          operator: null,
          isEditable: true
        })
      }

      filters = filters.concat(search.filters.dynamic.map(f => {
        return {
          id: f.id,
          name: f.display,
          icon: 'fa-filter',
          value: f.value,
          type: f.type,
          operator: f.operator,
          isEditable: f.preFilled === false
        }
      }))

      return filters
    }
  }
}
</script>
