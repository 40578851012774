<template>
  <div v-if="user" class="w-full flex flex-col gap-2">
    <div v-if="user.lockStatus?.disabled ?? false" class="flex items-center justify-between gap-2 bg-red-300 p-2 rounded">
      <span class="flex items-center gap-1">
        <i class="fas fa-warning" />
        <div>This user account is locked due to too many failed login attempts.</div>
      </span>
      <div class="btn btn-theme" @click="unlockAccount">Unlock Account</div>
    </div>
    <div class="flex flex-col">
      <div>Username</div>
      <input class="form-control" :disabled="user.id !== null" placeholder="username" v-model="user.username" />
    </div>
    <div class="flex flex-col">
      <div>Email</div>
      <input class="form-control" placeholder="email" v-model="user.email" />
    </div>
    <div class="flex flex-col">
      <div>First Name</div>
      <input class="form-control" placeholder="first name" v-model="user.firstName" />
    </div>
    <div class="flex flex-col">
      <div>Last Name</div>
      <input class="form-control" placeholder="last name" v-model="user.lastName" />
    </div>
    <div class="flex flex-col">
      <div>Groups</div>
      <v-select :options="groups" v-model="user.groups" :multiple="true" placeholder="select the groups for this user" :get-option-label="group => group.name" :append-to-body="true"></v-select>
    </div>
    <div class="flex flex-col">
      <div>Roles</div>
      <small>Direct role grants is discouraged, roles should be given through group memberships.</small>
      <v-select :options="roles" v-model="user.roles" :multiple="true" placeholder="select the roles for this user" :get-option-label="role => role.name" :append-to-body="true"></v-select>
    </div>

    <template v-if="user.password.setPassword">
      <div class="flex flex-col">
        <div>Password</div>
        <input class="form-control" placeholder="password" v-model="user.password.value" />
      </div>
      <div class="form-control bg-transparent border-0">
        <input type="checkbox" id="requirePassowrdChange" name="requirePassowrdChange" v-model="user.password.requirePasswordChange">
        <label for="requirePassowrdChange" class="ml-1">Require User to Change Password</label>
      </div>
    </template>
    <button v-else class="btn btn-theme-muted" @click="user.password.setPassword = true"><i class="fas fa-lock mr-1" />Update Password</button>
  </div>
</template>

<script>
import FullScreenLoadingMixin from '@/mixins/FullScreenLoadingMixin'

export default {
  name: 'user-editor',
  mixins: [
    FullScreenLoadingMixin
  ],
  emits: ['update:modelValue'],
  props: {
    modelValue: { type: Object, required: true }
  },
  data () {
    return {
      user: null,
      groups: [],
      roles: []
    }
  },
  watch: {
    modelValue () {
      this.resetModel()
    },
    user: {
      handler () {
        this.$emit('update:modelValue', this.user)
      },
      deep: true
    }
  },
  methods: {
    resetModel () {
      this.user = this.modelValue
    },
    async unlockAccount () {
      if (this.user === null) return

      try {
        this.showLoading('Unlocking User', null)
        await this.$store.dispatch('unlockUserAccount', this.user.id)
        this.user.lockStatus = null
      } catch (error) {
        console.error('Error unlocking user account', error)
        this.$swal.fire({
          icon: 'error',
          title: 'Unlock Failed',
          text: 'An unknown error occurred while unlocking the user account.',
          allowOutsideClick: false,
          allowEscapeKey: false
        })
      } finally {
        this.hideLoading()
      }
    }
  },
  mounted () {
    this.resetModel()

    const self = this
    this.$store.dispatch('fetchAdminEntities', 'groups').then(response => {
      self.groups = response.data.map(group => {
        return { id: group.id, name: group.name }
      })
    }).catch(error => {
      console.error('error loading user groups', error)
    })

    this.$store.dispatch('fetchAdminEntities', 'roles').then(response => {
      self.roles = response.data.map(role => {
        return { id: role.id, name: role.name }
      })
    }).catch(error => {
      console.error('error loading user roles', error)
    })
  }
}
</script>
