<template>
  <entity-editor
    entity-type-display="Roles"
    entity-type="roles"
    id-column="name"
    :disabled-actions="['disable', 'edit', 'copy', 'export', 'import']"
    :columns="[]">
  </entity-editor>
</template>

<script>
import EntityEditor from '@/components/admin/EntityEditor'

export default {
  name: 'role-settings',
  components: {
    EntityEditor
  }
}
</script>
