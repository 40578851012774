<template>
  <template v-for="route in routes" :key="route.name">
    <router-link
      v-if="!disabledLinks.includes(route.name)"
      :to="{ name: route.name }"
      class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300"
    >
      <i :class="['mr-2', route.icon.type, route.icon.name]"></i>
      <span class="text-sm">{{ route.display }}</span>
    </router-link>
  </template>
</template>

<script>
export default {
  name: 'sdk-child-menu',
  data () {
    return {
      routes: [
        {
          name: 'JavaSDK',
          icon: {
            name: 'fa-java',
            type: 'fab'
          },
          display: 'Java SDK'
        },
        {
          name: 'PythonSDK',
          icon: {
            name: 'fa-python',
            type: 'fab'
          },
          display: 'Python SDK'
        },
        {
          name: 'CLI',
          icon: {
            name: 'fa-laptop-code',
            type: 'fas'
          },
          display: 'CLI'
        }
      ]
    }
  },
  computed: {
    disabledLinks () {
      return this.$store.state.disabledLinks
    }
  }
}
</script>
