<template>
  <template v-for="route in routes" :key="route.name">
    <router-link v-if="!disabledLinks.includes(route.name)" :to="{name: route.name }" class="p-2 rounded flex items-center w-full hover:no-underline hover:text-white hover:bg-theme-300">
    <i :class="['fa mr-2', route.icon]"></i>
    <span class="text-sm">{{route.display}}</span>
    </router-link>
  </template>
</template>

<script>
export default {
  name: 'admin-child-menu',
  data () {
    return {
      routes: [
        {
          name: 'About',
          icon: 'fa-circle-info',
          display: 'About'
        },
        {
          name: 'Auditing',
          icon: 'fa-book',
          display: 'Audit Logs'
        },
        {
          name: 'ConfigurationSettings',
          icon: 'fa-toggle-on',
          display: 'Configurations'
        },
        {
          name: 'DocSettings',
          icon: 'fa-file',
          display: 'Docs'
        },
        {
          name: 'ExternalDatasetSettings',
          icon: 'fa-magnifying-glass-arrow-right',
          display: 'External Datasets'
        },
        {
          name: 'FilesetSettings',
          icon: 'fa-folder',
          display: 'Filesets'
        },
        {
          name: 'FlowEnvironmentSettings',
          icon: 'fa-clone',
          display: 'Flow Environments'
        },
        {
          name: 'FlowGroupSettings',
          icon: 'fa-chart-diagram',
          display: 'Flow Groups'
        },
        {
          name: 'GroupSettings',
          icon: 'fa-user-group',
          display: 'Groups'
        },
        {
          name: 'NoticeSettings',
          icon: 'fa-bell',
          display: 'Information Notices'
        },
        {
          name: 'InternalDatasetSettings',
          icon: 'fa-search',
          display: 'Internal Datasets'
        },
        {
          name: 'JoltSpecificationSettings',
          icon: 'fa-bolt-lightning',
          display: 'Jolt Specifications'
        },
        {
          name: 'RoleSettings',
          icon: 'fa-key',
          display: 'Roles'
        },
        {
          name: 'UserSettings',
          icon: 'fa-person',
          display: 'Users'
        }
      ]
    }
  },
  computed: {
    disabledLinks () {
      return this.$store.state.disabledLinks
    }
  }
}
</script>
